.container {
    display: flex;
    min-height: 100vh;
}

.logoContainer {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #284788;
}

.seal {
    margin-bottom: 1.5rem;
}

.vector {
    margin-bottom: 1rem;
}

.subText {
    color: white;
    font-weight: 400;
    font-size: 1.5rem;
    font-style: normal;
    line-height: 1.5rem;
}